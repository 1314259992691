import React from 'react';
import style from './delivery.module.css';
import first from '../../assets/ico/fast-delivery_116471.png';
import second from '../../assets/ico/warehouse_116463.png';
import tree from '../../assets/ico/home-delivery_116466.png';

const Delivery = () => {
    return (
        <div className={style.main} id='delivery'>
            <h2 className={style.title}>Как осуществляется доставка</h2>
            <div className={style.pos}>
                <div className={style.blockInfo}>
                    <div className={style.width}>
                        <img src={first} alt="" className={style.blockImg}/>
                    </div>

                    <p className={style.blockP}>После выбора товара свяжитесь с нами через по номеру телефона или электронную почту, оставив свои контактные данные</p>
                </div>
                <div className={style.blockInfo}>
                    <div className={style.width}>
                        <img src={second} alt="" className={style.blockImg}/>
                    </div>

                    <p className={style.blockP}>Сбор и доставка заказа осуществляется за 2-3 дня</p>
                </div>
                <div className={style.blockInfo}>
                    <div className={style.width}>
                        <img src={tree} alt="" className={style.blockImg}/>
                    </div>

                    <p className={style.blockP}>Доставка до любой транспортной компании бесплатно</p>
                </div>
            </div>
        </div>
    );
};

export default Delivery;