import React, {useContext} from 'react';
import style from './itemBasket.module.css'
import {Link} from "react-router-dom";
import {Context} from "../../context/context";

const ItemBasket = (props) => {
    const {basket, addNewItem, setBasket} = useContext(Context)

    const closeOne = (e) => {
        e.preventDefault()
        setBasket(basket.filter(item => item.href !== props.info.href))
    }

    return (
            <div className={style.main}>
                <Link to={props.info.href} className={style.link}>
                    <div className={style.img}>
                        <img className={style.image} src={props.info.img} alt=""/>
                    </div>

                    <div className={style.position}>
                        <p className={style.name}>{props.info.title}</p>
                        <p style={{textAlign: 'left'}}>
                        {
                            props.info.text.length > 50
                                ? props.info.text.slice(0, 150)
                                : props.info.text
                        }
                        </p>
                    </div>

                    <p className={style.price}>{props.info.price}</p>

                    <button onClick={closeOne} className={style.close}>+</button>
                </Link>
            </div>
    );
};

export default ItemBasket;