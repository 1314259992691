import React from 'react';
import style from './productTile.module.css';
import {NavLink} from "react-router-dom";

const ProductTile = (props) => {
    const handleClickScrollUp = (e) => {
        e.preventDefault();
        const element = document.getElementById('all');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={style.main} onClick={handleClickScrollUp}>
            <img className={style.img} src={props.img} alt="" />
            <NavLink to={props.href ? `/${props.typeHref}/:${props.typeId}/type/:${props.id}` : `/${props.typeHref}/:${props.id}`}  className={style.NavLink}>
                <div className={style.position}>
                    <h3 className={style.title}>{props.title}</h3>
                    <p className={style.text}>{props.text.indexOf("На рынке с 1992 года • КРЕПЁЖ и ручной инструмент • Общий ассортимент более 20 000 наименований") > 0  ? "" : props.text}</p>
                </div>
            </NavLink>
        </div>
    );
};

export default ProductTile;