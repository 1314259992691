import React from 'react';
import style from './about.module.css';

const About = () => {
    return (
        <div className={style.main} id='about'>
            <h2 className={style.title}>Компания OMEGA</h2>
            <div className={style.img}></div>
            <p className={style.text}>Организация ОМЕГА – ведущий поставщик крепежных изделий, инструментов и метизов на рынке уже 9 лет. Наша компания славится качеством продукции, быстрой доставкой и партнёрством с такими крупными организациями, как 'Леруа Мерлен' и 'Формула М2'. <br/><br/>Основные черты нашей организации:</p>
            <ol className={style.text} style={{marginLeft: "50px"}}>
                <li>Долгий стаж на рынке: На протяжении 9 лет мы непрерывно развивались и совершенствовались, чтобы обеспечивать наших клиентов лучшими решениями.</li>
                <li>Качество продукции: Мы работаем только с надежными поставщиками и гарантируем высокое качество наших товаров. Все наши продукты проходят строгий контроль качества.</li>
                <li>Широкий ассортимент: Мы предлагаем широкий ассортимент крепежных изделий, инструментов и метизов, удовлетворяя потребности как профессиональных строителей, так и обычных потребителей.</li>
                <li>Быстрая доставка: Мы понимаем важность своевременной поставки. Наша слаженная логистическая система позволяет нам обеспечивать быструю доставку заказов.</li>
                <li>Партнёрство с крупными организациями: Мы гордимся партнёрством с такими известными организациями, как "Леруа Мерлен",  "Формула М2", «Гвоздилка» и «Профкрепеж» что свидетельствует о нашей репутации и надёжности.</li>
            </ol>
            <p className={style.text2}>Мы гордимся нашей историей и стремимся продолжать предоставлять выдающийся сервис и качественные товары нашим клиентам.</p>
        </div>
    );
};

export default About;