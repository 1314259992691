import React, {useContext, useEffect, useRef, useState} from 'react';
import style from './headerBlock.module.css';
import {NavLink} from "react-router-dom";
import bolt from "../../assets/json/krep/bolt/data.json";
import magnit from "../../assets/json/mag/data.json";
import takelazh from '../../assets/json/tacelaj/data.json';
import himiya from '../../assets/json/himiya/data.json';
import podshipniki from '../../assets/json/podshipniki-kolca-i-salniki/data.json';
import lenta from '../../assets/json/lenta/data.json';
import pruzhiny from '../../assets/json/pruzhiny/data.json';
import zamki from '../../assets/json/zamki/data.json';
import santehnika from '../../assets/json/santehnika/data.json';
import vint from '../../assets/json/krep/vint/data (2).json';
import shpilka from '../../assets/json/krep/shpilka/data.json';
import gayka from '../../assets/json/krep/gayka/data (3).json';
import shajby from '../../assets/json/krep/shajby/data.json';
import gvozdi from '../../assets/json/krep/gvozdi/data.json';
import samorezy from '../../assets/json/krep/samorezy/data.json';
import ankera from '../../assets/json/krep/ankera/data.json';
import zaklepki from '../../assets/json/krep/zaklepki/data.json';
import perfokrepezh from '../../assets/json/krep/perfokrepezh/data.json';
import shplinty from '../../assets/json/krep/shplinty/data.json';
import shponk from '../../assets/json/krep/shponk/data.json';
import shtifty from '../../assets/json/krep/shtifty/data.json';
import homuty from '../../assets/json/krep/homuty/data.json';
import vtulki from '../../assets/json/krep/vtulki/data.json';
import zaglushki from '../../assets/json/krep/zaglushki/data.json';
import komplektujushhie from '../../assets/json/krep/komplektujushhie/data.json';
import Search from "./search/search";
import logo from "../../assets/image/logo__2_negate.png"
import {Context} from "../context/context";

const HeaderBlock = () => {
    let mainArray = []
    mainArray.push(bolt)
    mainArray.push(magnit)
    mainArray.push(takelazh)
    mainArray.push(himiya)
    mainArray.push(podshipniki)
    mainArray.push(lenta)
    mainArray.push(pruzhiny)
    mainArray.push(zamki)
    mainArray.push(santehnika)
    mainArray.push(vint)
    mainArray.push(shpilka)
    mainArray.push(gayka)
    mainArray.push(shajby)
    mainArray.push(gvozdi)
    mainArray.push(samorezy)
    mainArray.push(ankera)
    mainArray.push(zaklepki)
    mainArray.push(perfokrepezh)
    mainArray.push(shplinty)
    mainArray.push(shponk)
    mainArray.push(shtifty)
    mainArray.push(homuty)
    mainArray.push(vtulki)
    mainArray.push(zaglushki)
    mainArray.push(komplektujushhie)

    const handleClickScrollUp = () => {
        const element = document.getElementById('contact');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleClickScrollUpShop = () => {
        const element = document.getElementById('allShop');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleClickScrollUpDelivery = () => {
        const element = document.getElementById('delivery');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleClickScrollUpAbout = () => {
        const element = document.getElementById('about');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [searchedArray, setSearchedArray] = useState(mainArray);
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        if (searchString.length === 0) {
            setSearchedArray(mainArray);
        } else {
            const searchedObjects = [];
            mainArray.forEach((main, i) => {
                main.forEach((singleHeroObject, indexId) => {
                    if (singleHeroObject.headerSecond){
                        singleHeroObject.headerSecond.forEach((next, index) => {
                            next.items.forEach((itemElement, indexElement) => {
                                if (itemElement.title.toLowerCase().includes(searchString.toLowerCase())) {
                                    searchedObjects.push({
                                        id: itemElement.id,
                                        title: itemElement.title,
                                        price: itemElement.price,
                                        header: i,
                                        headerId: indexId,
                                        type: index,
                                        item: indexElement,
                                    });
                                }
                            })
                        })

                    } else {
                        singleHeroObject.items.forEach((next, index) => {
                            if (next.title.toLowerCase().includes(searchString.toLowerCase())) {
                                searchedObjects.push({
                                    id: next.id,
                                    title: next.title,
                                    price: next.price,
                                    header: i,
                                    headerId: indexId,
                                    type: 188923,
                                    item: index,
                                });
                            }
                        })
                    }
                });
            })
            setSearchedArray(searchedObjects);
        }
    }, [searchString]);

    const [focused, setFocused] = useState(false)
    const onFocus = (e) => {
        e.preventDefault()
        setFocused(true)
    }
    const onBlur = () => {
        setFocused(false)

    }

    const {basket, addNewItem, setBasket} = useContext(Context)

    return (
        <header>
            <div className={style.mainTitlePosition}>
                <div className={style.posText}>
                    <img src={logo} alt="" className={style.image}/>
                </div>
            </div>

            <div className={style.functionalBlock}>
                <input
                    className={style.search}
                    type="text"
                    value={searchString}
                    onChange={(e) => {
                        e.preventDefault()
                        setSearchString(e.target.value)
                    }}
                    onFocus={onFocus} onBlur={() => setTimeout(onBlur, 200)}
                    placeholder="Поиск товаров..."
                    style={focused && searchString !== "" ? {borderRadius: "20px 20px 0 0"} : {}}
                />

                {
                    focused ? <Search mainText={searchedArray} input={searchString}/> : ""
                }

                <div className={style.menuBlock} id="app">
                    <NavLink to="/" className={style.NavLink} onClick={() => setTimeout(handleClickScrollUpShop, 200)}><div className={style.componentMenu}>Магазин</div></NavLink>
                    <NavLink to="/basket" className={style.NavLink}><div className={style.componentMenu} style={{display: 'flex', alignItems: 'center'}}>Корзина {Array.from(new Set(basket)).length > 0 ? (<span className={style.span}>{Array.from(new Set(basket)).length}</span>) : ''}</div></NavLink>
                    <NavLink to="/delivery" className={style.NavLink} onClick={() => setTimeout(handleClickScrollUpDelivery, 200)}><div className={style.componentMenu}>Доставка</div></NavLink>
                    <div className={style.componentMenu} onClick={handleClickScrollUp}>Контакты</div>
                    <NavLink to="/about" className={style.NavLink} onClick={() => setTimeout(handleClickScrollUpAbout, 200)}><div className={style.componentMenu}>О нас</div></NavLink>
                </div>
            </div>
        </header>
    );
};

export default HeaderBlock;