import React from 'react';
import style from './itemProduct.module.css';
import {NavLink} from "react-router-dom";

const ItemProduct = (props) => {
    const handleClickScrollUp = () => {
        const element = document.getElementById('app');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className={style.main}>
            <NavLink to={`/${props.typeHref}/:${props.headerId}${Number.isInteger(props.typeId) ? `/type/:${props.typeId}` : ""}/item/:${props.content.id}`} className={style.navLink} onClick={handleClickScrollUp}>
                <img className={style.img} src={props.content.images[0]} alt=""/>
                <div className={style.position}>
                    <p className={style.type}>{props.type}</p>
                    <h3 className={style.title}>{props.content.title}</h3>
                    <p className={style.price}>{props.content.price}</p>
                    <button className={style.button}>Подробнее</button>
                </div>
            </NavLink>
        </div>
    );
};

export default ItemProduct;