import React from 'react';
import style from './mainShop.module.css';
import TypeBlock from "./typeBlock/typeBlock";
import krep from '../../assets/image/Krepjozh-min-qa9g5eeh6w4n8cu78puwov62rz16yrqomutp2kgiy8.png'
import takelaj from '../../assets/image/Takelazh-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png'
import podshib from '../../assets/image/Podshipniki-kolca-i-salniki-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png'
import magnit from '../../assets/image/Magnity-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png.webp'
import prujin from '../../assets/image/Pruzhiny-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png.webp'
import himiya from '../../assets/image/Himiya-min-qa9g5eeh6w4n8cu78puwov62rz16yrqomutp2kgiy8.png.webp'
import lenta from '../../assets/image/Stroitelnye-i-montazhnye-lenty-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png.webp'
import zamki from '../../assets/image/Zamochno-skobyanye-izdeliya-i-mebelnaya-furnitura-min-qa9g5eeh6w4n8cu78puwov62rz16yrqomutp2kgiy8.png.webp'
import santehnika from '../../assets/image/Santehnika-min-qa9g5fcbdq5xjysu389j9cxjdcwk6gueyzh6juf4s0.png.webp'
import vint from '../../assets/json/krep/vint/Bolt-DIN-912-polnaya-rezba-cink-8.8-300x300.png';
import bolt from '../../assets/json/krep/bolt/Bolt-DIN-933-cink-300x300.png'
import shpilka from '../../assets/json/krep/shpilka/6445783734.jpg';
import gayka from '../../assets/json/krep/gayka/c3944910d00f8c0f4e7af1a35e8c7007.jpg';
import shajby from '../../assets/json/krep/shajby/shajba-usilennaja-nerzh.webp';
import gvozdi from '../../assets/json/krep/gvozdi/da73753868f6bc09434e512627577e4d.jpg';
import samorezy from '../../assets/json/krep/samorezy/d98a2d599ed532ab4339373b27691464.jpeg';
import ankera from '../../assets/json/krep/ankera/macbook_1.jpg';
import zaklepki from '../../assets/json/krep/zaklepki/500_original.webp';
import perfokrepezh from '../../assets/json/krep/perfokrepezh/IMG_2858.jpg';
import shplinty from '../../assets/json/krep/shplinty/shplint.jpg';
import shponk from '../../assets/json/krep/shponk/3e88562859c4188cf9fbeef41440da7e.jpg';
import shtifty from '../../assets/json/krep/shtifty/3129.jpg';
import homuty from '../../assets/json/krep/homuty/20180424144508.jpeg';
import vtulki from '../../assets/json/krep/vtulki/MV25003-946x946.jpg';
import zaglushki from '../../assets/json/krep/zaglushki/Hb3db660425b442e182921db98fe82ab4d.jpg';
import komplektujushhie from '../../assets/json/krep/komplektujushhie/a64d59023d245c2e70ff43359bfe5f38.jpg';
import back from '../../assets/ico/arrow_left_back_icon_221067.png'
import {Link} from "react-router-dom";

const All = () => {
    return(
        <>
            <TypeBlock bac={krep} text="Крепёж" to="/krep"/>
            <TypeBlock bac={takelaj} text="Такелаж" to="/takelazh"/>
            <TypeBlock bac={podshib} text="Подшипники, кольца и сальники" to="/podshipniki"/>
            <TypeBlock bac={magnit} text="Магниты" to="/magnit"/>
            <TypeBlock bac={prujin} text="Пружины" to="/pruzhiny"/>
            <TypeBlock bac={himiya} text="Химия" to="/himiya"/>
            <TypeBlock bac={lenta} text="Строительные и монтажные ленты" to="/lenta"/>
            <TypeBlock bac={zamki} text="Замочно-скобяные изделия и мебельная фурнитура" to="/zamki"/>
            <TypeBlock bac={santehnika} text="Сантехника" to="/santehnika"/>
        </>
    )
}

const Krep = () => {
    return(
        <>
            <Link to={'/'} style={{position: "absolute"}}>
                <img src={back} className={style.back}/>
            </Link>
            <TypeBlock bac={bolt} text="Болты" to="/bolt"/>
            <TypeBlock bac={vint} text="Винты" to="/vint"/>

            <TypeBlock bac={shpilka} text="Шпильки" to="/shpilka"/>
            <TypeBlock bac={gayka} text="Гайки" to="/gayka"/>
            <TypeBlock bac={shajby} text="Шайбы" to="/shajby"/>
            <TypeBlock bac={gvozdi} text="Гвозди" to="/gvozdi"/>
            <TypeBlock bac={samorezy} text="Шурупы и саморезы" to="/samorezy"/>
            <TypeBlock bac={ankera} text="Анкера и дюбели" to="/ankera"/>
            <TypeBlock bac={zaklepki} text="Заклёпки" to="/zaklepki"/>
            <TypeBlock bac={perfokrepezh} text="Перфокрепеж и кронштейны" to="/perfokrepezh"/>
            <TypeBlock bac={shplinty} text="Шплинты" to="/shplinty"/>
            <TypeBlock bac={shponk} text="Шпонки" to="/shponk"/>
            <TypeBlock bac={shtifty} text="Штифты" to="/shtifty"/>
            <TypeBlock bac={homuty} text="Хомуты и скобы" to="/homuty"/>
            <TypeBlock bac={vtulki} text="Втулки и муфты" to="/vtulki"/>
            <TypeBlock bac={zaglushki} text="Заглушки и колпачки" to="/zaglushki"/>
            <TypeBlock bac={komplektujushhie} text="Комплектующие для отделочных работ" to="/komplektujushhie"/>
        </>
    )
}

const MainShop = (props) => {
    return (
        <main id="allShop">
            <div className={style.shop} style={props.all ? {padding: "10px"} : {padding: "50px 10px 10px"}}>
                {
                    props.all ? <All /> : <Krep />
                }
            </div>
        </main>
    );
};

export default MainShop;