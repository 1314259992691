import './App.css';
import HeaderBlock from "./components/headerBlock/headerBlock";
import MainShop from "./components/mainShop/mainShop";
import InfoAboutCompany from "./components/infoAboutCompany/infoAboutCompany";
import Footer from "./components/footer/footer";
import {Route, Routes, useLocation} from "react-router-dom";
import json from "./assets/json/krep/bolt/data.json";
import magnit from "./assets/json/mag/data.json";
import takelazh from './assets/json/tacelaj/data.json';
import himiya from './assets/json/himiya/data.json';
import podshipniki from './assets/json/podshipniki-kolca-i-salniki/data.json';
import lenta from './assets/json/lenta/data.json';
import pruzhiny from './assets/json/pruzhiny/data.json';
import zamki from './assets/json/zamki/data.json';
import santehnika from './assets/json/santehnika/data.json';
import bolt from './assets/json/krep/bolt/data.json';
import vint from './assets/json/krep/vint/data (2).json';
import shpilka from './assets/json/krep/shpilka/data.json';
import gayka from './assets/json/krep/gayka/data (3).json';
import shajby from './assets/json/krep/shajby/data.json';
import gvozdi from './assets/json/krep/gvozdi/data.json';
import samorezy from './assets/json/krep/samorezy/data.json';
import ankera from './assets/json/krep/ankera/data.json';
import zaklepki from './assets/json/krep/zaklepki/data.json';
import perfokrepezh from './assets/json/krep/perfokrepezh/data.json';
import shplinty from './assets/json/krep/shplinty/data.json';
import shponk from './assets/json/krep/shponk/data.json';
import shtifty from './assets/json/krep/shtifty/data.json';
import homuty from './assets/json/krep/homuty/data.json';
import vtulki from './assets/json/krep/vtulki/data.json';
import zaglushki from './assets/json/krep/zaglushki/data.json';
import komplektujushhie from './assets/json/krep/komplektujushhie/data.json';
import React, {useState} from "react";
import About from "./components/about/about";
import Delivery from "./components/delivery/delivery";
import Search from "./components/headerBlock/search/search";
import RouteType from "./components/routeType/routeType";
import HeaderIdCheck from "./components/routeType/HeaderIdCheck/HeaderIdCheck";
import Form from "./components/form/form";
import Ur from "./components/ur/ur";
import {Path} from "./components/routeType/path/path";
import Basket from "./components/basket/basket";
import {Context} from "./components/context/context";

function App() {
    let src = useLocation();

    let headerId = 0;
    let itemId = 0;
    let typeId = 0;
    let valuesHref = [];


    if(src.pathname.indexOf('magnit') >= 0){
        valuesHref = HeaderIdCheck('magnit', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('takelazh') >= 0){
        valuesHref = HeaderIdCheck('takelazh', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('himiya') >= 0){
        valuesHref = HeaderIdCheck('himiya', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('podshipniki') >= 0){
        valuesHref = HeaderIdCheck('podshipniki', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('lenta') >= 0){
        valuesHref = HeaderIdCheck('lenta', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('pruzhiny') >= 0){
        valuesHref = HeaderIdCheck('pruzhiny', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('zamki') >= 0){
        valuesHref = HeaderIdCheck('zamki', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('santehnika') >= 0){
        valuesHref = HeaderIdCheck('santehnika', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('bolt') >= 0){
        valuesHref = HeaderIdCheck('bolt', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('vint') >= 0){
        valuesHref = HeaderIdCheck('vint', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('shpilka') >= 0){
        valuesHref = HeaderIdCheck('shpilka', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('gayka') >= 0){
        valuesHref = HeaderIdCheck('gayka', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('shajby') >= 0){
        valuesHref = HeaderIdCheck('shajby', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('gvozdi') >= 0){
        valuesHref = HeaderIdCheck('gvozdi', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('samorezy') >= 0){
        valuesHref = HeaderIdCheck('samorezy', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('ankera') >= 0){
        valuesHref = HeaderIdCheck('ankera', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('zaklepki') >= 0){
        valuesHref = HeaderIdCheck('zaklepki', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('perfokrepezh') >= 0){
        valuesHref = HeaderIdCheck('perfokrepezh', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('shplinty') >= 0){
        valuesHref = HeaderIdCheck('shplinty', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('shponk') >= 0){
        valuesHref = HeaderIdCheck('shponk', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('shtifty') >= 0){
        valuesHref = HeaderIdCheck('shtifty', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('homuty') >= 0){
        valuesHref = HeaderIdCheck('homuty', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('vtulki') >= 0){
        valuesHref = HeaderIdCheck('vtulki', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('zaglushki') >= 0){
        valuesHref = HeaderIdCheck('zaglushki', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else if(src.pathname.indexOf('komplektujushhie') >= 0){
        valuesHref = HeaderIdCheck('komplektujushhie', src, headerId, typeId, itemId);
        headerId = parseInt(valuesHref[0]);
        typeId = parseInt(valuesHref[1]);
        itemId = parseInt(valuesHref[2]);
    }else {
        headerId = 0;
        typeId = 0;
        itemId = 0;
    }

    const location = useLocation()
    let result = Path(location)

    const [basket, setBasket] = useState([])

    const addNewItem = (object) => {
        setBasket(per => [...per, object])
    }

  return (
    <div className="App">
        <Context.Provider value={{basket, addNewItem, setBasket}}>
            <HeaderBlock />
            <RouteType name={'magnit'} file={magnit} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'takelazh'} file={takelazh} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'himiya'} file={himiya} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'podshipniki'} file={podshipniki} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'lenta'} file={lenta} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'pruzhiny'} file={pruzhiny} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'zamki'} file={zamki} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'santehnika'} file={santehnika} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'bolt'} file={bolt} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'vint'} file={vint} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>

            <RouteType name={'shpilka'} file={shpilka} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'gayka'} file={gayka} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'shajby'} file={shajby} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'gvozdi'} file={gvozdi} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'samorezy'} file={samorezy} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'ankera'} file={ankera} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'zaklepki'} file={zaklepki} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'perfokrepezh'} file={perfokrepezh} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'shplinty'} file={shplinty} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'shponk'} file={shponk} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'shtifty'} file={shtifty} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'homuty'} file={homuty} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'vtulki'} file={vtulki} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'zaglushki'} file={zaglushki} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <RouteType name={'komplektujushhie'} file={komplektujushhie} headerId={headerId} typeId={typeId} itemId={itemId} result={result}/>
            <Routes>
                <Route path="/" element={<MainShop all={true}/>} />
                <Route path="/krep" element={<MainShop all={false}/>} />
                <Route path="/about" element={<About />} />
                <Route path="/delivery" element={<Delivery />} />
                <Route path="/search" element={<Search />} />
                <Route path="/formItem" element={<Form />} />
                <Route path="/basket" element={<Basket />} />
            </Routes>
            <InfoAboutCompany />
            <Footer />
            <Ur />
        </Context.Provider>
    </div>
  );
}

export default App;
