import React from 'react';

const HeaderIdCheck = (typeFile, src, headerId, typeId, itemId) => {
    if (src.pathname.indexOf(`/${typeFile}/:`) >= 0 && src.pathname.indexOf('item') < 0 && src.pathname.indexOf('type') < 0){
        let cash = src.pathname.split("/")
        headerId = cash[cash.length - 1].replace(":", '')
    }
    else if (src.pathname.indexOf(`/${typeFile}/:`) >= 0 && src.pathname.indexOf('/type/:') < 0 && src.pathname.indexOf('item') >= 0){
        let cash = src.pathname.split("/")
        headerId = cash[2].replace(":", '')
        itemId = cash[cash.length - 1].replace(":", '')
    }
    else if (src.pathname.indexOf(`/${typeFile}/:`) >= 0 && src.pathname.indexOf('/type/:') >= 0 && src.pathname.indexOf('item') < 0){
        let cash = src.pathname.split("/")
        headerId = cash[2].replace(":", '')
        typeId = cash[cash.length - 1].replace(":", '')
    }
    else if (src.pathname.indexOf(`/${typeFile}/:`) >= 0 && src.pathname.indexOf('/type/:') >= 0 && src.pathname.indexOf('item') >= 0){
        let cash = src.pathname.split("/")
        itemId = cash[cash.length - 1].replace(":", '')
        typeId = cash[4].replace(":", '')
        headerId = cash[2].replace(":", '')
    }
    return [headerId, typeId, itemId]
}

export default HeaderIdCheck;