import React from 'react';
import style from "./blockInfo.module.css";

const BlockInfo = (props) => {
    return (
        <div className={style.blockInfo}>
            <img className={style.img} src={props.image} alt=""/>
            <p className={style.text}>{props.text}</p>
        </div>
    );
};

export default BlockInfo;