import React from 'react';
import style from './pagination.module.css'

const Pagination = ({totalCount, perPage, paginate}) => {
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(totalCount / perPage); i++) {
        pageNumbers.push(i)
    }

    const handleClickScrollUp = () => {
        const element = document.getElementById('all');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={style.main}>
            <ul className={style.ul}>
                {
                    pageNumbers.map(number => (
                        <a href="!#" onClick={(e) => {e.preventDefault(); paginate(number); handleClickScrollUp()}} className={style.a}>
                            <li key={number} className={style.li}>
                                {number}
                            </li>
                        </a>
                    ))
                }
            </ul>
        </div>
    );
};

export default Pagination;