import React, {useContext, useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import style from './form.module.css';
import {useLocation, useParams} from "react-router-dom";
import {Context} from "../context/context";

const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_do6gmm9', 'template_9fwfyju', form.current, 'j_aP-dAkmZ8PtH1bo')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    const [fio, setFio] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [count, setCount] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");

    function handleSubmit(e) {
        // clearing the values
        setFio("");
        setPhone("");
        setEmail("");
        setCount("");
        setAddress("");
        setMessage("");
    }

    const location = useLocation()

    const {basket, addNewItem} = useContext(Context)

    return (
        <div className={style.main}>
            <h2 className={style.title}>Заявка</h2>
            <p className={style.text}>Заполните форму, и мы ответим вам в течении часа</p>
            <form ref={form} onSubmit={sendEmail} className={style.position}>
                {
                    basket.map(item => (
                        <div className={style.item}>
                            <input value={item.title} type="text" name="to_title" className={style.input} readOnly style={{textAlign: "center"}}/>
                            <input value={item.price} type="text" name="to_price" className={style.input} readOnly style={{textAlign: "center"}}/>
                            <input placeholder="Количество" type="number" name="to_count" className={style.input} style={{textAlign: "center"}}/>
                        </div>
                    ))
                }
                <input placeholder="Ваше Ф.И.О" type="text" name="to_name" className={style.input} value={fio} onChange={(e) => setFio(e.target.value)}/>
                <input placeholder="Ваш телефон" type="tel" id="phone" name="phone"
                       required className={style.input} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <input placeholder="Ваш Email" type="email" id="email" name="email" size="30" required className={style.input} value={email} onChange={(e) => setEmail(e.target.value)}/>
                <input placeholder="Количество товара" type="number" name="to_count" className={style.input} value={count} onChange={(e) => setCount(e.target.value)}/>
                <input placeholder="Адрес" type="text" name="to_address" className={style.input} value={address} onChange={(e) => setAddress(e.target.value)}/>
                <textarea placeholder="Комментарий" name='message' className={style.input + ' ' + style.area} value={message} onChange={(e) => setMessage(e.target.value)}/>

                <button className={style.button} onClick={() => setTimeout(handleSubmit, 300)} type="submit">Отправить</button>
            </form>
        </div>
    );
};

export default Form;