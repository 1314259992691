import React from 'react';
import style from './ur.module.css';
import logo from '../../assets/image/logo__2_negate.png';

const Ur = () => {
    return (
        <div className={style.main}>
            <p className={style.text}><span className={style.type}>ОГРН:</span> 1142225010498</p>
            <p className={style.text}><span className={style.type}>ИНН:</span> 2225151316</p>
            <p className={style.text}><span className={style.type}>КПП:</span> 222301001</p>
            <p className={style.text}><span className={style.type}>Юридический адрес:</span> 656060, Алтайский край, г. Барнаул, ул. Шукшина, д. 34/316</p>
            <div className={style.position}>
                <img src={logo} alt="" className={style.img}/>
            </div>
        </div>
    );
};

export default Ur;