import React, {useContext, useState} from 'react';
import style from './item.module.css'
import {Link, useLocation} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Context} from "../context/context";

const Item = (props) => {
    const [type, setType] = useState(true);

    const onClickSwitchType = (e) =>{
        e.preventDefault();
        setType(!type);
    }

    let a = []
    let b = []
    for (let i = 0; i < props.content.table.length / 2; i++){
        a.push(props.content.table[i])
    }
    for (let i = 0; i < props.content.table.length / 2; i++){
        b.push(props.content.table[i + props.content.table.length / 2])
    }

    let screenW = window.screen.width;

    const {basket, addNewItem} = useContext(Context)
    const [add, setAdd] = useState(false)

    const location = useLocation()

    return (
        <div className={style.main} id='item'>
            <div className={style.blockPath}>
                <div style={{display: 'flex'}} className={style.ll}>
                    <Link to={'/'} className={style.link}><p className={style.hrefPath}>{screenW < 800 ? ('Главная'.length > 20 ? 'Главная'.slice(0, 20) + '...' : 'Главная') : 'Главная'}</p></Link>
                    <p className={style.next}>{">"}</p>
                </div>
                {
                    props.result.map(item => (
                        <div style={{display: 'flex'}} className={style.ll}>
                            <Link to={item.name === 'Крепёж' ? '/krep' : '/' + item.href} className={style.link}><p className={style.hrefPath}>{screenW < 800 ? (item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name) : item.name}</p></Link>
                            <p className={style.next}>{">"}</p>
                        </div>
                    ))
                }
            </div>
            <div className={style.center}>
                <div className={style.posLeft}>
                    <img className={style.image} src={props.content.images[0]} alt=""/>
                </div>

                <div className={style.posRight}>
                    <h2 className={style.title}>{props.content.title}</h2>
                    <p className={style.price}>{props.content.price + " - шт"}</p>
                    <div className={style.contact}>
                        <p className={style.text}>Для заказа товара свяжитесь с менеджером по телефону или почте</p>
                        <div className={style.flex}>
                            <div className={style.connect}>
                                <CopyToClipboard text={"omega_krep@mail.ru"}>
                                    <p className={style.email}><span style={{color: "black"}}>E-mail:</span> omega_krep@mail.ru</p>
                                </CopyToClipboard>
                                <CopyToClipboard text={"+7 (963) 577-57-33"}>
                                    <p className={style.phone}><span style={{color: "black"}}>Телефон:</span> +7 (963) 577-57-33</p>
                                </CopyToClipboard>
                                <CopyToClipboard text={"+7 (995) 245-08-48"}>
                                    <p className={style.phone}><span style={{color: "black"}}>Телефон:</span> +7 (995) 245-08-48</p>
                                </CopyToClipboard>
                            </div>
                            <button onClick={() => {
                                addNewItem({
                                    title: props.content.title,
                                    text: props.content.text,
                                    img: props.content.images[0],
                                    price: props.content.price,
                                    href: location.pathname
                                });
                                setAdd(!add)
                            }} disabled={add} className={style.button}>Добавить в корзину</button>
                        </div>
                    </div>

                </div>
            </div>

            <div className={style.posBottom}>
                <div className={style.switch}>
                    <button className={style.op} onClick={onClickSwitchType} disabled={type ? true : false}>Описание</button>
                    <button className={style.det} onClick={onClickSwitchType} disabled={type ? false : true}>Детали</button>
                </div>
                <div className={style.ditails} style={type ? {display: "block"} : {display: "none"}}>{props.content.text}</div>
                <div className={style.table} style={type ? {display: "none"} : {display: "flex"}}>
                    <div className={style.block}>
                        {
                            a.map((items, index) => (
                                <>
                                    <div className={style.margin}>{items}</div>
                                </>
                            ))
                        }
                    </div>
                    <div className={style.block}>
                        {
                            b.map((items, index) => (
                                <>
                                    <div className={style.border}>{items}</div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Item;