import React from 'react';
import style from './infoAboutCompany.module.css';
import BlockInfo from "../headerBlock/blockInfo/blockInfo";
import img1 from '../../assets/ico/4544844-business-comerce-consultation-delivery-shop_121444.png';
import img2 from '../../assets/ico/3741751-bussiness-dellivery-ecommerce-marketplace-onlinestore-store_10890.png';
import img3 from '../../assets/ico/4544852-business-comerce-delivery-shop_121452.png';
import img4 from '../../assets/ico/4544847-business-comerce-delivery-shop_121447.png';
import img5 from '../../assets/ico/4544822-business-comerce-delivery-mail-shop_121421.png';
import img6 from '../../assets/ico/4544845-business-comerce-delivery-list-shop_121445.png';

const InfoAboutCompany = () => {
    return (
        <aside>
            <div className={style.grid}>
                <BlockInfo image={img1} text="Более 20'000 позиций в наличии"/>
                <BlockInfo image={img2} text="Бесплатная доставка по г. Барнаул при сумме заказа от 5’000 ₽, в отдаленные районы от 10’000₽"/>
                <BlockInfo image={img3} text="Осуществляем доставку в любую точку города России ТК СДЭК, ТК Деловые Линии, ТК Энергия"/>
                <BlockInfo image={img4} text="Используя наш интернет-магазин, вы тратите меньше времени на подбор товара, а также не придется ждать комплектацию заказа"/>
                <BlockInfo image={img5} text="Расскажем обо всех особенностях и характеристиках товара. Сможем выполнить подбор крепежа исходя из сложности выполняемых работ, подскажем все нюансы по монтажу."/>
                <BlockInfo image={img6} text="Наличный и безналичный расчет"/>
            </div>
        </aside>
    );
};

export default InfoAboutCompany;