import React, {useState} from 'react';
import style from './footer.module.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import phone from '../../assets/ico/phone_4307.png';
import email from '../../assets/ico/4105936-email-envelope-mail-message-snail-mail_113938.png';

const Open = () => {
    return(
        <div className={style.open}>
            <h3 className={style.titleOpen}>Копировать</h3>
        </div>
    )
}

const Footer = () => {
    const [isHoveringPhone, setIsHoveringPhone] = useState(false);
    const [isHoveringPhone2, setIsHoveringPhone2] = useState(false);
    const [isHoveringEmail, setIsHoveringEmail] = useState(false);
    const handleMouseOverPhone = () => {
        setIsHoveringPhone(true);
    };

    const handleMouseOutPhone = () => {
        setIsHoveringPhone(false);
    };
    const handleMouseOverPhone2 = () => {
        setIsHoveringPhone2(true);
    };

    const handleMouseOutPhone2 = () => {
        setIsHoveringPhone2(false);
    };

    const handleMouseOverEmail = () => {
        setIsHoveringEmail(true);
    };

    const handleMouseOutEmail = () => {
        setIsHoveringEmail(false);
    };

    return (
        <footer id="contact">
            <div className={style.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d128237.15004610221!2d83.658456602223!3d53.328561804071605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x42dda1e8c72eeeab%3A0xb0e7bbef8d87b503!2z0JHQsNGA0L3QsNGD0LssINCQ0LvRgtCw0LnRgdC60LjQuSDQutGA0LDQuQ!5e0!3m2!1sru!2sru!4v1692523343919!5m2!1sru!2sru"
                    className={style.iframe} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className={style.infoBlock}>
                <p className={style.info}>Организовываем доставку в любую точку городу Барнаул</p>
                <p className={style.info}>пн-пт 9:00-18:00</p>

                <div style={{display: "flex", alignItems: "center"}}>
                    <img src={phone} className={style.icoPhone}/>
                    <CopyToClipboard text={"+7 (963) 577-57-33"}>
                        <p className={style.info} onClick={() => {setIsHoveringPhone(false)}} style={!isHoveringPhone ? {cursor: "pointer"} : {cursor: "pointer", color: "green"}} onMouseOver={handleMouseOverPhone} onMouseOut={handleMouseOutPhone}>+7 (963) 577-57-33</p>
                    </CopyToClipboard>
                    {isHoveringPhone && <Open />}
                </div>

                <div style={{display: "flex", alignItems: "center"}}>
                    <img src={phone} className={style.icoPhone}/>
                    <CopyToClipboard text={"+7 (995) 245-08-48"}>
                        <p className={style.info} onClick={() => {setIsHoveringPhone2(false)}} style={!isHoveringPhone2 ? {cursor: "pointer"} : {cursor: "pointer", color: "green"}} onMouseOver={handleMouseOverPhone2} onMouseOut={handleMouseOutPhone2}>+7 (995) 245-08-48</p>
                    </CopyToClipboard>
                    {isHoveringPhone2 && <Open />}
                </div>

                <div style={{display: "flex", alignItems: "center"}}>
                    <img src={email} className={style.icoPhone}/>
                    <CopyToClipboard text={"omega_krep@mail.ru"}>
                        <p className={style.info} onClick={() => {setIsHoveringEmail(false)}} style={!isHoveringEmail ? {cursor: "pointer"} : {cursor: "pointer", color: "green"}} onMouseOver={handleMouseOverEmail} onMouseOut={handleMouseOutEmail}>omega_krep@mail.ru</p>
                    </CopyToClipboard>
                    {isHoveringEmail && <Open />}

                </div>
                {/*<div className={style.position}>*/}
                {/*    <div className={style.ico}></div>*/}
                {/*    <div className={style.ico}></div>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
};

export default Footer;