import React, {useState} from 'react';
import style from './allTypes.module.css';
import ProductTile from "./productTile/productTile";
import ItemProduct from "./itemProduct/itemProduct";
import Pagination from "./itemProduct/pagination/pagination";
import {Link} from "react-router-dom";

const AllTypes = (props) => {
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage] = useState(10)

    const lastIndex = currentPage * perPage
    const firstIndex = lastIndex - perPage
    let current = 0
    if (!props.check){
        if(Number.isInteger(props.typeId)){
            current = props.list[props.headerId][props.typeId].slice(firstIndex, lastIndex)
        }
        else {
            current = props.list[props.headerId].slice(firstIndex, lastIndex)
        }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    let screenW = window.screen.width;

    return (
        <div className={style.main} id="all">
            <div className={style.shop}>
                <div className={style.blockPath}>
                    <div style={{display: 'flex'}} className={style.ll}>
                        <Link to={'/'} className={style.link}><p className={style.hrefPath}>{screenW < 800 ? ('Главная'.length > 20 ? 'Главная'.slice(0, 20) + '...' : 'Главная') : 'Главная'}</p></Link>
                        <p className={style.next}>{">"}</p>
                    </div>
                    {
                        props.result.map(item => (
                            <div style={{display: 'flex'}} className={style.ll}>
                                <Link to={item.name === 'Крепёж' ? '/krep' : '/' + item.href} className={style.link}><p className={style.hrefPath}>{screenW < 800 ? (item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name) : item.name}</p></Link>
                                <p className={style.next}>{">"}</p>
                            </div>
                        ))
                    }
                </div>

                <div style={{paddingBottom: "140px"}}>
                    {
                        props.check ? props.list : current
                    }
                </div>
                    {
                        props.check ? "" : <Pagination perPage={perPage} totalCount={Number.isInteger(props.typeId) ? props.list[props.headerId][props.typeId].length : props.list[props.headerId].length} paginate={paginate}/>
                    }
            </div>
        </div>
    );
};

export default AllTypes;