import React, {useContext, useEffect, useState} from 'react';
import style from './basket.module.css';
import ItemBasket from "./itemBasket/itemBasket";
import {Context} from "../context/context";
import {Link} from "react-router-dom";

const Basket = () => {
    const {basket, addNewItem} = useContext(Context)

    return (
        <div className={style.main}>
            <h2 className={style.title}>Корзина</h2>

            <div className={style.block}>
                {
                    basket.length > 0
                        ? basket.map(item => (
                            <ItemBasket info={item}/>
                        ))
                        : (<p style={{fontSize: "1.2em", textAlign: 'right'}}>Добавьте товары в корзину</p>)
                }
                <Link to='/formItem'>
                    <button className={style.button}>Оставить заявку</button>
                </Link>
            </div>
        </div>
    );
};

export default Basket;