import React from 'react';
import {Route, Routes} from "react-router-dom";
import AllTypes from "../allTypes/allTypes";
import ProductTile from "../allTypes/productTile/productTile";
import ItemProduct from "../allTypes/itemProduct/itemProduct";
import Item from "../item/item";

const RouteType = ({name, file, headerId, typeId, itemId, result}) => {
    return(
        <Routes>
            <Route path={`/${name}`} element={<AllTypes result={result} check={true} list={file.map((item) => <ProductTile key={item.id} id={item.id} typeHref={`${name}`} title={item.header} img={item.headerSecond ? item.headerSecond[0].items[0].images[0] : item.items[0].images[0]} text={item.headerSecond ? item.headerSecond[0].items[0].text : item.items[0].text}/>)}/>} />
            <Route path={`/${name}/:Id`} element={<AllTypes result={result} headerId={parseInt(headerId)}
                                                            list={file.map((item) => item.headerSecond ? item.headerSecond.map((element) => <ProductTile href={true} typeHref={`${name}`} key={element.id} id={element.id} typeId={item.id} title={element.type} img={element.items[0].images[0]} text={element.items[0].text}/>) :
                                                                item.items.map((element) => <ItemProduct key={element.id} typeHref={`${name}`} headerId={headerId} content={element} type={item.header}/>))}/>} />
            <Route path={`/${name}/:Id/type/:Id`} element={<AllTypes result={result} headerId={parseInt(headerId)} typeId={parseInt(typeId)} list={file.map((item) => item.id === parseInt(headerId) ? item.headerSecond ? item.headerSecond.map((element) => element.id === parseInt(typeId) ? element.items.map(block => <ItemProduct key={block.id} typeHref={`${name}`} typeId={parseInt(typeId)} headerId={headerId} content={block} type={element.type}/>) : "") : "" : "")}/>} />
            {
                headerId < file.length ? [<Route path={`/${name}/:Id/item/:Id`} element={<Item result={result} content={typeof file[headerId].headerSecond !== 'undefined' ? (typeId < file[headerId].headerSecond.length ? file[headerId].headerSecond[typeId].items[itemId] : "") : file[headerId].items[itemId]}/>} />, <Route path={`/${name}/:Id/type/:Id/item/:Id`} element={<Item result={result} content={typeof file[headerId].headerSecond !== 'undefined' ? (typeId < file[headerId].headerSecond.length ? file[headerId].headerSecond[typeId].items[itemId] : "") : file[headerId].items[itemId]}/>} />] : ''
            }
        </Routes>
    )
}

export default RouteType;