import React, {useEffect, useState} from 'react';
import style from './search.module.css'
import {NavLink} from "react-router-dom";

const PanelItem = (props) => {
    const handleClickScrollUpShop = () => {
        const element = document.getElementById('item');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    let a = ["bolt", "magnit", "takelazh", "himiya", "podshipniki", "lenta", "pruzhiny", "zamki", "santehnika", "vint", "shpilka", "gayka", "shajby", "gvozdi", "samorezy", "ankera", "zaklepki", "perfokrepezh", "shplinty", "shponk", "shtifty", "homuty", "vtulki", "zaglushki", "komplektujushhie"]
    return(
        <>
            <NavLink className={style.navLink} to={props.type !== 188923 ? `${a[props.header]}/:${props.headerId}/type/:${props.type}/item/:${props.item}` : `${a[props.header]}/:${props.headerId}/item/:${props.item}`} onClick={() => setTimeout(handleClickScrollUpShop, 200)}>
                <div className={style.panelItem}>
                    <h3 className={style.titleItem}>{props.title}</h3>
                    <p>{props.price}</p>
                </div>
            </NavLink>
        </>
    )
}

const Search = (props) => {
    const [searchString, setSearchString] = useState([]);

    useEffect(() => {
        setSearchString(props.mainText)
    }, [searchString]);
    return (
        <div className={style.main}>
            {
                props.input ? props.mainText.slice(0, 50).map(item => <PanelItem id={item.id} title={item.title} price={item.price} header={item.header} headerId={item.headerId} type={item.type} item={item.item}/>) : ""
            }
        </div>
    );
};

export default Search;