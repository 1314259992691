import magnit from "../../../assets/json/mag/data.json";
import takelazh from '../../../assets/json/tacelaj/data.json';
import himiya from '../../../assets/json/himiya/data.json';
import podshipniki from '../../../assets/json/podshipniki-kolca-i-salniki/data.json';
import lenta from '../../../assets/json/lenta/data.json';
import pruzhiny from '../../../assets/json/pruzhiny/data.json';
import zamki from '../../../assets/json/zamki/data.json';
import santehnika from '../../../assets/json/santehnika/data.json';
import bolt from '../../../assets/json/krep/bolt/data.json';
import vint from '../../../assets/json/krep/vint/data (2).json';
import shpilka from '../../../assets/json/krep/shpilka/data.json';
import gayka from '../../../assets/json/krep/gayka/data (3).json';
import shajby from '../../../assets/json/krep/shajby/data.json';
import gvozdi from '../../../assets/json/krep/gvozdi/data.json';
import samorezy from '../../../assets/json/krep/samorezy/data.json';
import ankera from '../../../assets/json/krep/ankera/data.json';
import zaklepki from '../../../assets/json/krep/zaklepki/data.json';
import perfokrepezh from '../../../assets/json/krep/perfokrepezh/data.json';
import shplinty from '../../../assets/json/krep/shplinty/data.json';
import shponk from '../../../assets/json/krep/shponk/data.json';
import shtifty from '../../../assets/json/krep/shtifty/data.json';
import homuty from '../../../assets/json/krep/homuty/data.json';
import vtulki from '../../../assets/json/krep/vtulki/data.json';
import zaglushki from '../../../assets/json/krep/zaglushki/data.json';
import komplektujushhie from '../../../assets/json/krep/komplektujushhie/data.json';

const Check = (string, json, src, result, location) => {
    result.push({name: string, href: src[1]})
    if(src.includes('type')){
        result.push({name: json[src[2][src[2].length - 1]].header, href: src[1] + '/' + src[2]})
        result.push({name: json[src[2][src[2].length - 1]].headerSecond[src[4][src[4].length - 1]].type, href: src[1] + '/' + src[2] + '/' + src[3] + '/' + src[4]})
        if(src.includes('item')){
            result.push({name: json[src[2][src[2].length - 1]].headerSecond[src[4][src[4].length - 1]].items[src[6][src[6].length - 1]].title, href: location.pathname})
        }
    } else {
        if(src.length !== 2){
            result.push({name: json[src[2][src[2].length - 1]].header, href: src[1] + '/' + src[2]})
        }
        if(src.includes('item')){
            result.push({name: json[src[2][src[2].length - 1]].items[src[4][src[4].length - 1]].title, href: location.pathname})
        }
    }
}

export const Path = (location) => {
    let krep = ['bolt', 'vint', 'shpilka', 'gayka', 'shajby', 'gvozdi', 'samorezy', 'ankera', 'zaklepki', 'perfokrepezh', 'shplinty', 'shponk', 'shtifty', 'homuty', 'vtulki', 'zaglushki', 'komplektujushhie']
    let src = location.pathname.split("/")
    let result = []

    if(krep.includes(src[1])){
        result.push({name: 'Крепёж', href: '/'})
        if(src[1] === 'bolt'){
            Check('Болты', bolt, src, result, location)
        } else if(src[1] === 'vint'){
            Check('Винты', vint, src, result, location)
        } else if(src[1] === 'shpilka'){
            Check('Шпильки', shpilka, src, result, location)
        } else if(src[1] === 'gayka'){
            Check('Гайки', gayka, src, result, location)
        } else if(src[1] === 'shajby'){
            Check('Шайбы', shajby, src, result, location)
        } else if(src[1] === 'gvozdi'){
            Check('Гвозди', gvozdi, src, result, location)
        } else if(src[1] === 'samorezy'){
            Check('Шурупы и саморезы', samorezy, src, result, location)
        } else if(src[1] === 'ankera'){
            Check('Анкера и дюбели', ankera, src, result, location)
        } else if(src[1] === 'zaklepki'){
            Check('Заклёпки', zaklepki, src, result, location)
        } else if(src[1] === 'perfokrepezh'){
            Check('Перфокрепеж и кронштейны', perfokrepezh, src, result, location)
        } else if(src[1] === 'shplinty'){
            Check('Шплинты', shplinty, src, result, location)
        } else if(src[1] === 'shponk'){
            Check('Шпонки', shponk, src, result, location)
        } else if(src[1] === 'shtifty'){
            Check('Штифты', shtifty, src, result, location)
        } else if(src[1] === 'homuty'){
            Check('Хомуты и скобы', homuty, src, result, location)
        } else if(src[1] === 'vtulki'){
            Check('Втулки и муфты', vtulki, src, result, location)
        } else if(src[1] === 'zaglushki'){
            Check('Заглушки и колпачки', zaglushki, src, result, location)
        } else if(src[1] === 'komplektujushhie'){
            Check('Комплектующие для отделочных работ', komplektujushhie, src, result, location)
        }
    } else if(src[1] === 'magnit'){
        Check('Магниты', magnit, src, result, location)
    } else if(src[1] === 'takelazh'){
        Check('Такелаж', takelazh, src, result, location)
    } else if(src[1] === 'himiya'){
        Check('Химия', himiya, src, result, location)
    } else if(src[1] === 'podshipniki'){
        Check('Подшипники, кольца и сальники', podshipniki, src, result, location)
    } else if(src[1] === 'lenta'){
        Check('Строительные и монтажные ленты', lenta, src, result, location)
    } else if(src[1] === 'pruzhiny'){
        Check('Пружины', pruzhiny, src, result, location)
    } else if(src[1] === 'zamki'){
        Check('Замочно-скобяные изделия', zamki, src, result, location)
    } else if(src[1] === 'santehnika'){
        Check('Сантехника', santehnika, src, result, location)
    }

    return result;
};