import React from 'react';
import style from './typeBlock.module.css';
import ico from '../../../assets/ico/4105931-add-to-cart-buy-cart-sell-shop-shopping-cart_113919.ico'
import {NavLink} from "react-router-dom";

const TypeBlock = (props) => {
    return (
        <NavLink to={props.to} className={style.navLink}>
            <div className={style.main} style={{backgroundImage: `url(${props.bac})`}}>
                <div className={style.pos}>
                    <img className={style.img} src={ico} alt=""/>
                    <p className={style.text}>{props.text}</p>
                </div>
            </div>
        </NavLink>
    );
};

export default TypeBlock;